/* eslint-disable */
// export default App

import React from 'react'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'

// routes
import { PublicRoute } from './routes-type'

// component
import CodeSplit from './components/code-split/code-split'
import About from './pages/about-pages'
import LandingPageSkeleton from './pages/landing-page/landing-page-skeleton'
import HeaderSkeleton from './components/layouts/header-new/header-skeleton'
import FooterSkelton from './components/layouts/footer/footer-skeleton'
import JobListSkeleton from './pages/job-list/job-list-skeleton'
import GA from './GoogleAnalytics'
import ReactPixel from 'react-facebook-pixel'
// component webview
import WebviewVendorFeedback from './pages/webview/webview-vendor/vendor-help-feedback'
import WebviewVendorDetail from './pages/webview/webview-vendor/vendor-help-detail'
import WebviewVendorSub from './pages/webview/webview-vendor/vendor-help-sub'
import WebviewVendor from './pages/webview/webview-vendor/vendor-help'
import WebviewPoint from './pages/webview/webview-user/webview-help-point'
import WebviewUserDetail from './pages/webview/webview-user/webview-help-detail'
import WebviewUserSub from './pages/webview/webview-user/webview-help-sub'
import WebviewUser from './pages/webview/webview-user/webview-help'
import RegisterVendor from './pages/register-vendor'

// gtmId: 'AW-698155596'

// const tagManagerArgs = {
//   gtmId: 'GTM-N9XRZCS'
// }

// TagManager.initialize(tagManagerArgs)

if (navigator.userAgent !== 'ReactSnap') {
  ReactPixel.init('2515174972063026')
  ReactPixel.pageView()
}

const headerResponsive =
  window.innerWidth > 1023
    ? import('./components/layouts/header-new')
    : import('./components/layouts/header-responsive')

const LandingPage = props => (
  <CodeSplit load={() => import('./pages/landing-page/landing-page')}>
    {Component =>
      Component === null ? (
        <LandingPageSkeleton></LandingPageSkeleton>
      ) : (
        <Component {...props} />
      )
    }
  </CodeSplit>
)

const Overview = props => (
  <CodeSplit load={() => import('./pages/overview')}>
    {Component =>
      Component === null ? (
        <LandingPageSkeleton></LandingPageSkeleton>
      ) : (
        <Component {...props} />
      )
    }
  </CodeSplit>
)

const KarirPage = props => (
  <CodeSplit load={() => import('./pages/karir')}>
    {Component =>
      Component === null ? (
        <LandingPageSkeleton></LandingPageSkeleton>
      ) : (
        <Component {...props} />
      )
    }
  </CodeSplit>
)

const App = props => {
  return (
    <Router>
      {GA.init() && <GA.RouteTracker />}
      <Route
        component={props => (
          <CodeSplit load={() => headerResponsive}>
            {Component =>
              Component === null ? (
                <HeaderSkeleton></HeaderSkeleton>
              ) : (
                <Component {...props} />
              )
            }
          </CodeSplit>
        )}
      />
      <Switch>
        <PublicRoute exact path="/" restricted component={LandingPage} />
        <PublicRoute
          exact
          path="/vendor/register"
          restricted
          component={() => {
            // window.location.href = 'https://halojasa.co.id/vendor/register'
            return null
          }}
        />
        <PublicRoute
          exact
          path="/vendor/register/1"
          restricted
          component={() => {
            // window.location.href = 'https://halojasa.co.id/vendor/register/1'
            return null
          }}
        />
        <PublicRoute
          exact
          path="/vendor/register/2"
          restricted
          component={() => {
            // window.location.href = 'https://halojasa.co.id/vendor/register/2'
            return null
          }}
        />
        <PublicRoute
          exact
          path="/vendor/register/3"
          restricted
          component={() => {
            // window.location.href = 'https://halojasa.co.id/vendor/register/3'
            return null
          }}
        />
        <PublicRoute
          exact
          path="/vendor/register/4"
          restricted
          component={() => {
            // window.location.href = 'https://halojasa.co.id/vendor/register/4'
            return null
          }}
        />
        <PublicRoute
          exact
          path="/vendor/register/5"
          restricted
          component={() => {
            // window.location.href = 'https://halojasa.co.id/vendor/register/5'
            return null
          }}
        />
        <PublicRoute
          exact
          path="/service/halo-clean/"
          restricted
          component={() => {
            window.location.href = 'https://halojasa.com/jasa-cleaning-service'
            return null
          }}
        />
        <PublicRoute
          exact
          path="/service/halo-auto/"
          restricted
          component={() => {
            window.location.href = 'https://halojasa.com/salon-mobil-pangilan'
            return null
          }}
        />
        <PublicRoute
          exact
          path="/service/halo-fix/"
          restricted
          component={() => {
            window.location.href = 'https://halojasa.com/service-ac-jakarta'
            return null
          }}
        />
        {/* <PublicRoute exact path="/vendor/register/:id" restricted component={LandingPage} /> */}
        <PublicRoute
          exact
          //          category="about"
          path="/overview/"
          restricted
          component={Overview}
        />
        <PublicRoute
          exact
          //          category="about"
          path="/karir/all/"
          restricted
          component={KarirPage}
        />
        <PublicRoute
          exact
          path="/about/job-list/"
          restricted
          component={props => (
            <CodeSplit load={() => import('./pages/job-list')}>
              {Component =>
                Component === null ? (
                  <JobListSkeleton></JobListSkeleton>
                ) : (
                  <Component {...props} />
                )
              }
            </CodeSplit>
          )}
        />
        {/* <PublicRoute
          exact
          path="/about/job-detail/"
          restricted
          component={props => (
            <CodeSplit load={() => import('./pages/job-detail')}>
              {Component =>
                Component === null ? (
                  <JobListSkeleton></JobListSkeleton>
                ) : (
                    <Component {...props} />
                  )
              }
            </CodeSplit>
          )}
        /> */}
        <PublicRoute
          exact
          path="/about/job-detail/lead-programmers"
          restricted
          component={props => (
            <CodeSplit load={() => import('./pages/job-detail')}>
              {Component =>
                Component === null ? (
                  <JobListSkeleton></JobListSkeleton>
                ) : (
                  <Component {...props} />
                )
              }
            </CodeSplit>
          )}
        />
        <PublicRoute
          exact
          path="/about/job-detail/senior-programmers"
          restricted
          component={props => (
            <CodeSplit load={() => import('./pages/job-detail')}>
              {Component =>
                Component === null ? (
                  <JobListSkeleton></JobListSkeleton>
                ) : (
                  <Component {...props} />
                )
              }
            </CodeSplit>
          )}
        />
        <PublicRoute
          exact
          path="/about/job-detail/marketing-strategy"
          restricted
          component={props => (
            <CodeSplit load={() => import('./pages/job-detail')}>
              {Component =>
                Component === null ? (
                  <JobListSkeleton></JobListSkeleton>
                ) : (
                  <Component {...props} />
                )
              }
            </CodeSplit>
          )}
        />
        <PublicRoute
          exact
          path="/about/job-detail/dev-ops"
          restricted
          component={props => (
            <CodeSplit load={() => import('./pages/job-detail')}>
              {Component =>
                Component === null ? (
                  <JobListSkeleton></JobListSkeleton>
                ) : (
                  <Component {...props} />
                )
              }
            </CodeSplit>
          )}
        />
        <PublicRoute
          exact
          path="/about/job-detail/back-end-nodejs"
          restricted
          component={props => (
            <CodeSplit load={() => import('./pages/job-detail')}>
              {Component =>
                Component === null ? (
                  <JobListSkeleton></JobListSkeleton>
                ) : (
                  <Component {...props} />
                )
              }
            </CodeSplit>
          )}
        />
        <PublicRoute
          exact
          path="/about/job-detail/frontend-programmer"
          restricted
          component={props => (
            <CodeSplit load={() => import('./pages/job-detail')}>
              {Component =>
                Component === null ? (
                  <JobListSkeleton></JobListSkeleton>
                ) : (
                  <Component {...props} />
                )
              }
            </CodeSplit>
          )}
        />
        <PublicRoute
          exact
          path="/about/job-detail/cto"
          restricted
          component={props => (
            <CodeSplit load={() => import('./pages/job-detail')}>
              {Component =>
                Component === null ? (
                  <JobListSkeleton></JobListSkeleton>
                ) : (
                  <Component {...props} />
                )
              }
            </CodeSplit>
          )}
        />
        <PublicRoute
          exact
          path="/about/job-detail/product-manager"
          restricted
          component={props => (
            <CodeSplit load={() => import('./pages/job-detail')}>
              {Component =>
                Component === null ? (
                  <JobListSkeleton></JobListSkeleton>
                ) : (
                  <Component {...props} />
                )
              }
            </CodeSplit>
          )}
        />
        <PublicRoute
          exact
          path="/about/job-detail/system-analyst"
          restricted
          component={props => (
            <CodeSplit load={() => import('./pages/job-detail')}>
              {Component =>
                Component === null ? (
                  <JobListSkeleton></JobListSkeleton>
                ) : (
                  <Component {...props} />
                )
              }
            </CodeSplit>
          )}
        />
        <PublicRoute
          exact
          path="/about/job-detail/social-media-strategy"
          restricted
          component={props => (
            <CodeSplit load={() => import('./pages/job-detail')}>
              {Component =>
                Component === null ? (
                  <JobListSkeleton></JobListSkeleton>
                ) : (
                  <Component {...props} />
                )
              }
            </CodeSplit>
          )}
        />
        <PublicRoute
          exact
          path="/about/job-detail/system-admin"
          restricted
          component={props => (
            <CodeSplit load={() => import('./pages/job-detail')}>
              {Component =>
                Component === null ? (
                  <JobListSkeleton></JobListSkeleton>
                ) : (
                  <Component {...props} />
                )
              }
            </CodeSplit>
          )}
        />
        <PublicRoute
          exact
          path="/about/job-detail/seo"
          restricted
          component={props => (
            <CodeSplit load={() => import('./pages/job-detail')}>
              {Component =>
                Component === null ? (
                  <JobListSkeleton></JobListSkeleton>
                ) : (
                  <Component {...props} />
                )
              }
            </CodeSplit>
          )}
        />
        <PublicRoute
          exact
          path="/about/job-detail/sem"
          restricted
          component={props => (
            <CodeSplit load={() => import('./pages/job-detail')}>
              {Component =>
                Component === null ? (
                  <JobListSkeleton></JobListSkeleton>
                ) : (
                  <Component {...props} />
                )
              }
            </CodeSplit>
          )}
        />
        <PublicRoute
          exact
          path="/about/job-detail/sales-marketing"
          restricted
          component={props => (
            <CodeSplit load={() => import('./pages/job-detail')}>
              {Component =>
                Component === null ? (
                  <JobListSkeleton></JobListSkeleton>
                ) : (
                  <Component {...props} />
                )
              }
            </CodeSplit>
          )}
        />
        <PublicRoute
          exact
          path="/about/job-detail/sales-marketing-manager"
          restricted
          component={props => (
            <CodeSplit load={() => import('./pages/job-detail')}>
              {Component =>
                Component === null ? (
                  <JobListSkeleton></JobListSkeleton>
                ) : (
                  <Component {...props} />
                )
              }
            </CodeSplit>
          )}
        />
        <PublicRoute
          exact
          path="/about/job-detail/qa"
          restricted
          component={props => (
            <CodeSplit load={() => import('./pages/job-detail')}>
              {Component =>
                Component === null ? (
                  <JobListSkeleton></JobListSkeleton>
                ) : (
                  <Component {...props} />
                )
              }
            </CodeSplit>
          )}
        />
        <PublicRoute
          exact
          path="/about/job-detail/qc"
          restricted
          component={props => (
            <CodeSplit load={() => import('./pages/job-detail')}>
              {Component =>
                Component === null ? (
                  <JobListSkeleton></JobListSkeleton>
                ) : (
                  <Component {...props} />
                )
              }
            </CodeSplit>
          )}
        />
        <PublicRoute
          exact
          path="/about/job-detail/pr"
          restricted
          component={props => (
            <CodeSplit load={() => import('./pages/job-detail')}>
              {Component =>
                Component === null ? (
                  <JobListSkeleton></JobListSkeleton>
                ) : (
                  <Component {...props} />
                )
              }
            </CodeSplit>
          )}
        />
        <PublicRoute
          exact
          path="/about/job-detail/operational-manager"
          restricted
          component={props => (
            <CodeSplit load={() => import('./pages/job-detail')}>
              {Component =>
                Component === null ? (
                  <JobListSkeleton></JobListSkeleton>
                ) : (
                  <Component {...props} />
                )
              }
            </CodeSplit>
          )}
        />
        <PublicRoute
          exact
          path="/about/job-detail/motion-graphic"
          restricted
          component={props => (
            <CodeSplit load={() => import('./pages/job-detail')}>
              {Component =>
                Component === null ? (
                  <JobListSkeleton></JobListSkeleton>
                ) : (
                  <Component {...props} />
                )
              }
            </CodeSplit>
          )}
        />
        <PublicRoute
          exact
          path="/about/job-detail/mobile-progammer"
          restricted
          component={props => (
            <CodeSplit load={() => import('./pages/job-detail')}>
              {Component =>
                Component === null ? (
                  <JobListSkeleton></JobListSkeleton>
                ) : (
                  <Component {...props} />
                )
              }
            </CodeSplit>
          )}
        />
        <PublicRoute
          exact
          path="/about/job-detail/laravel-progammer"
          restricted
          component={props => (
            <CodeSplit load={() => import('./pages/job-detail')}>
              {Component =>
                Component === null ? (
                  <JobListSkeleton></JobListSkeleton>
                ) : (
                  <Component {...props} />
                )
              }
            </CodeSplit>
          )}
        />
        <PublicRoute
          exact
          path="/about/job-detail/design-illustrator"
          restricted
          component={props => (
            <CodeSplit load={() => import('./pages/job-detail')}>
              {Component =>
                Component === null ? (
                  <JobListSkeleton></JobListSkeleton>
                ) : (
                  <Component {...props} />
                )
              }
            </CodeSplit>
          )}
        />
        <PublicRoute
          exact
          path="/about/job-detail/digital-marketing"
          restricted
          component={props => (
            <CodeSplit load={() => import('./pages/job-detail')}>
              {Component =>
                Component === null ? (
                  <JobListSkeleton></JobListSkeleton>
                ) : (
                  <Component {...props} />
                )
              }
            </CodeSplit>
          )}
        />
        <PublicRoute
          exact
          path="/about/job-detail/content-specialist"
          restricted
          component={props => (
            <CodeSplit load={() => import('./pages/job-detail')}>
              {Component =>
                Component === null ? (
                  <JobListSkeleton></JobListSkeleton>
                ) : (
                  <Component {...props} />
                )
              }
            </CodeSplit>
          )}
        />
        <PublicRoute
          exact
          path="/about/job-detail/data-analyst-specialist"
          restricted
          component={props => (
            <CodeSplit load={() => import('./pages/job-detail')}>
              {Component =>
                Component === null ? (
                  <JobListSkeleton></JobListSkeleton>
                ) : (
                  <Component {...props} />
                )
              }
            </CodeSplit>
          )}
        />
        <PublicRoute
          exact
          path="/about/job-detail/business-analyst"
          restricted
          component={props => (
            <CodeSplit load={() => import('./pages/job-detail')}>
              {Component =>
                Component === null ? (
                  <JobListSkeleton></JobListSkeleton>
                ) : (
                  <Component {...props} />
                )
              }
            </CodeSplit>
          )}
        />
        <PublicRoute
          exact
          path="/about/job-detail/android-dev"
          restricted
          component={props => (
            <CodeSplit load={() => import('./pages/job-detail')}>
              {Component =>
                Component === null ? (
                  <JobListSkeleton></JobListSkeleton>
                ) : (
                  <Component {...props} />
                )
              }
            </CodeSplit>
          )}
        />
        <PublicRoute
          exact
          path="/about/job-detail/admin-operational"
          restricted
          component={props => (
            <CodeSplit load={() => import('./pages/job-detail')}>
              {Component =>
                Component === null ? (
                  <JobListSkeleton></JobListSkeleton>
                ) : (
                  <Component {...props} />
                )
              }
            </CodeSplit>
          )}
        />
        <PublicRoute
          exact
          path="/reflexology-massage-pijat-panggilan/"
          restricted
          component={props => (
            <CodeSplit load={() => import('./pages/halo-massage')}>
              {Component =>
                Component === null ? (
                  <LandingPageSkeleton></LandingPageSkeleton>
                ) : (
                  <Component {...props} />
                )
              }
            </CodeSplit>
          )}
        />
        <PublicRoute
          path="/bantuan/tentang-kami/"
          component={props => (
            <CodeSplit load={() => import('./pages/about-pages/about')}>
              {Component =>
                Component === null ? (
                  <LandingPageSkeleton></LandingPageSkeleton>
                ) : (
                  <Component {...props} />
                )
              }
            </CodeSplit>
          )}
          exact
        />
        <PublicRoute
          exact
          path="/service-ac-jakarta/"
          restricted
          component={props => (
            <CodeSplit load={() => import('./pages/halo-fix')}>
              {Component =>
                Component === null ? (
                  <LandingPageSkeleton></LandingPageSkeleton>
                ) : (
                  <Component {...props} />
                )
              }
            </CodeSplit>
          )}
        />
        <PublicRoute
          exact
          path="/jasa-cleaning-service/"
          restricted
          component={props => (
            <CodeSplit load={() => import('./pages/halo-clean')}>
              {Component =>
                Component === null ? (
                  <LandingPageSkeleton></LandingPageSkeleton>
                ) : (
                  <Component {...props} />
                )
              }
            </CodeSplit>
          )}
        />
        <PublicRoute
          exact
          path="/salon-mobil-pangilan/"
          restricted
          component={props => (
            <CodeSplit load={() => import('./pages/halo-auto')}>
              {Component =>
                Component === null ? (
                  <LandingPageSkeleton></LandingPageSkeleton>
                ) : (
                  <Component {...props} />
                )
              }
            </CodeSplit>
          )}
        />
        {/* <Route
          exact
          path="/register-vendor/:id"
          component={() => (
            <CodeSplit load={() => import('./pages/landing-page/landing-page')}>
              {Component =>
                Component === null ? (
                  <LandingPageSkeleton></LandingPageSkeleton>
                ) : (
                    <Component {...props} />
                  )
              }
            </CodeSplit>
          )}
        /> */}
        <Route
          exact
          path="/webview-user/help-bantuan/"
          restricted
          component={WebviewUser}
        />
        <Route
          exact
          path="/webview-user/help-sub/"
          restricted
          component={WebviewUserSub}
        />
        <Route
          exact
          path="/webview-user-detail/"
          restricted
          component={WebviewUserDetail}
        />
        <Route
          exact
          path="/webview-user/help-point/"
          restricted
          component={WebviewPoint}
        />
        <Route
          exact
          path="/webview-vendor/help-bantuan/"
          restricted
          component={WebviewVendor}
        />
        <Route
          exact
          path="/webview-vendor/help-sub/"
          restricted
          component={WebviewVendorSub}
        />
        <Route
          exact
          path="/webview-vendor-detail/"
          restricted
          component={WebviewVendorDetail}
        />
        <Route
          exact
          path="/webview-vendor-feedback/"
          restricted
          component={WebviewVendorFeedback}
        />
        <Route
          exact
          path="/register-vendor/"
          restricted
          component={RegisterVendor}
        />
        <Route
          exact
          path="/register-vendor/1"
          restricted
          component={RegisterVendor}
        />
        <Route
          exact
          path="/register-vendor/2"
          restricted
          component={RegisterVendor}
        />
        <Route
          exact
          path="/register-vendor/3"
          restricted
          component={RegisterVendor}
        />
        <Route
          exact
          path="/register-vendor/4"
          restricted
          component={RegisterVendor}
        />
        <About></About>
      </Switch>
      <Route
        component={props => (
          <CodeSplit load={() => import('./components/layouts/footer')}>
            {Component =>
              Component === null ? (
                <FooterSkelton></FooterSkelton>
              ) : (
                <Component {...props} />
              )
            }
          </CodeSplit>
        )}
      />
    </Router>
  )
}

export default App
